<template lang="pug">
	.main-wrapper.feriados-listas
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-3
					Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
				.p-col-9.ta-right
					h1.text-header.text-secondary MedClub / <b>Feriados do Estabelecimento</b>

		Dialog.dialogApagar(header='Remover feriado' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover o feriado <b>{{ dialogApagar_data.ds_feriado }}</b>?
			.ta-center.my-4
				ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
				Button.p-button-danger(v-else label='Remover' @click='remove()')

		Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' v-if='waiting == false || estabelecimentoLoaded' mode="indeterminate")
			.p-grid.p-fluid
				.p-col-12.p-md-6
					label.form-label Nome do Feriado:
					.p-inputgroup
						InputText(placeholder='Feriado'
							@keyup.enter.native='applyFilters()'
							@input='checkEmpty_ds_feriado()'
							v-model='filters.ds_feriado'
							v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
						)
				.p-col-12.p-md-6
					label.form-label Data:
					.p-inputgroup
						Calendar.filter-data( v-model='filters.dt_feriado' dateFormat="dd/mm/yy" :locale="ptbr"
							:manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
						Button(icon='jam jam-search' @click='applyFilters()')
		.ta-right.mb-2
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/feriados/salvar/0/')")

		ProgressBar(v-if='waiting' mode="indeterminate")
		
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			DataView.dataview.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
						Panel.panel-list.ta-center(:header="props.data.ds_feriado" style='position: relative')
							.ta-left
								p <b>Data:</b> {{ props.data.dt_feriado }}
								p <b>Nome do Procedimento:</b> {{ props.data.ds_feriado }}

			Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

			Panel.datatable(header='Lista de Feriados')
				DataTable(:value="list")
					Column(headerStyle='width: 20%;' field='dt_feriado_f' header='Data')
					Column(headerStyle='width: 65%;' field='ds_feriado' header='Descrição')
					Column(headerStyle='width: 15%;' header='Ações')
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Editar'"
									icon="jam jam-write"
									@click="goToEditar(props.data.id)"
								)
								Button.p-button-raised.p-button-rounded.p-button-danger(
									v-tooltip.top="'Apagar'"
									icon="jam jam-minus-circle"
									@click="dialogApagar = true; dialogApagar_data = props.data"
								)
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
	.feriados-listas {
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 16px 0;
				text-align: center;
				&.ex  {  background-color: #e4f8e1; }
				&.a  { background-color: #faf3dd; }
			}
		}
		.dialogApagar {
			.p-progress-spinner {
				width: 29px;
				height: auto;
			}
		}
		.dialogEditarObservacoes {
			width: 96%;
			max-width: 600px;
			textarea {
				height: 200px;
			}
		}
		.status-indicator {
			display: inline-block;
			font-size: 0;
			width: 24px;
			height: 24px;
			line-height: 36px;
			border-radius: 50%;
			background-color: #94c860;
			border: 1px solid #84b553;
			transition: 250ms;
			cursor: pointer;
			&:hover { background-color: #84b553; }
			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
				&:hover { background-color: #b33030; }
			}
			.jam {
				color: #fff;
			}
		}
		.waitingStatus, .waitingObservacoes {
			width: 29px;
			height: auto;
		}
        .textarea-informacoes,
        .textarea-preparo {
            height: 200px !important;
            overflow-y: auto !important;
            resize: none;
        }
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Button from 'primevue/button'
	import Tooltip from 'primevue/tooltip'
	import Dialog from 'primevue/dialog'
	import Dropdown from 'primevue/dropdown'    
	import InputText from 'primevue/inputtext'
	import Textarea from 'primevue/textarea'
	import ProgressSpinner from 'primevue/progressspinner'
	import Calendar from 'primevue/calendar'
	import moment from 'moment'

	import { Feriados, Estabelecimento } from './../../middleware'
	import { pCalendarLocale_ptbr } from './../../utils'
	import wsConfigs from './../../middleware/configs'
	export default {
		beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'feriados') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
		created () {
			this.getEstabelecimentos()
		},
		components: { ProgressBar, DataView, Panel, Paginator, DataTable, Textarea,
			Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, Dropdown, Calendar },
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: [],
				ptbr: pCalendarLocale_ptbr,
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingApagar: false,
				waitingStatus: 0,
				dialogApagar: false,
				dialogApagar_data: {},
				filters: {
					ds_feriado: '',
					dt_feriado: '',
				},
				options: {
					estabelecimentos: []
				},
				paginator: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				estabelecimentoLoaded:true
			}
		},
		methods: {
			getEstabelecimentos(){
				Estabelecimento.findAll().then(response => {
					if (response.status == 200) {
						response.data.forEach(e => {
							this.options.estabelecimentos.push({ text: e.nm_fantasia, value: e.id })
						})
						this.options.estabelecimentos.unshift({ text: 'TODOS', value: null })
						this.estabelecimentoLoaded = true
					}
				})
			},
			goToEditar (id) {
				let nav = {}
				if (this.filters.ds_feriado) nav.ds_feriado = this.filters.ds_feriado
				this.$router.push(`/feriados/salvar/${ id }/`)
			},
			getList (params) {
				this.waiting = true
				return Feriados.findAll(params).then(response => {
					if (response.status == 200) {
						this.paginator.count = response.data.count
						response.data.results.forEach(item => {
							item.dt_feriado_f = moment(item.dt_feriado).format('DD/MM/YYYY')
						})
						this.list = response.data.results
					}
					this.waiting = false
					return true
				})
			},
			checkEmpty_ds_feriado () {
				if (! this.filters.ds_feriado) this.applyFilters()
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
				
				console.log(params)
				if ( params['dt_feriado'] != '' && params['dt_feriado'] != undefined ){
					params['dt_feriado'] = moment(params['dt_feriado']).format('YYYY-MM-DD')
				}

				this.getList(params)
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
			remove () {
				this.waitingApagar = true
				Feriados.remove(this.dialogApagar_data.id).then(response => {
					this.waitingApagar = false
					if (([200, 201, 204]).includes(response.status)) {
						this.$toast.info('Procedimento removido com sucesso', { duration: 3000 })
						this.dialogApagar = false
						this.applyFilters()
					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			}
		}
	}
</script>